/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { AmastyItemsType } from '../../type/AmastyPromo.type';
import { AMASTY_FREE_GIFT_POPUP } from '../AmastyGiftAdd/AmastyGiftAdd.config';
import AmastyPopupItems from '../AmastyPopupItems';

import './AmastyGiftPopup.style';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftPopup/Component */
export class AmastyGiftPopupComponent extends PureComponent {
    static propTypes = {
        amItems: AmastyItemsType.isRequired,
        handlePopupClose: PropTypes.func.isRequired
    };

    renderPopupContainer() {
        const { amItems } = this.props;

        return (
            <div className="ampromo-popup-container">
                <AmastyPopupItems
                  amItems={ amItems }
                />
            </div>
        );
    }

    renderContent() {
        return (
            <div className="ampromo-items-content">
                { this.renderPopupContainer() }
            </div>
        );
    }

    render() {
        const { handlePopupClose } = this.props;

        return (
            <Popup
              id={ AMASTY_FREE_GIFT_POPUP }
              mix={ { block: 'AmastyGift', elem: 'Popup' } }
              onClose={ handlePopupClose }
            >
                <div className="ampromo-overlay" block="AmastyGiftPopup">
                    { this.renderContent() }
                </div>
            </Popup>
        );
    }
}

export default AmastyGiftPopupComponent;
