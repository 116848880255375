import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './NotificationIcon.style';

/** @namespace Scandipwa/Component/NotificationIcon/Component */
export class NotificationIconComponent extends PureComponent {
    static propTypes = {
        type: PropTypes.string.isRequired
    };

    render() {
        const { type } = this.props;

        if (type === 'success') {
            return (
                <div block="NotificationIcon" mods={ { isSuccess: true } }>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 4L6.125 11L3 7.81818" strokeWidth="1.4" strokeLinecap="round" />
                    </svg>
                </div>
            );
        } if (type === 'error') {
            return (
                <svg
                  block="NotificationIcon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  mods={ { isError: true } }
                >
                    <path d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514
                    22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589
                    7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
                    />
                    <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" />
                </svg>
            );
        }

        return (
                <svg
                  block="NotificationIcon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  mods={ { isInfo: true } }
                >
                    <path d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514
                    22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589
                    7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
                    />
                    <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" />
                </svg>
        );
    }
}

export default NotificationIconComponent;
