/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    DETAILS_STEP
} from '@scandipwa/scandipwa/src/route/Checkout/Checkout.config';
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';
import { appendWithStoreCode } from '@scandipwa/scandipwa/src/util/Url';
import PropTypes from 'prop-types';

const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        minimumOrderAmount: state.CartReducer.cartTotals.minimum_order_amount

    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    minimumOrderAmount: PropTypes.shape({
        minimum_order_amount_reached: PropTypes.bool,
        minimum_order_description: PropTypes.string
    })
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    minimumOrderAmount: {}

});

const handleRedirectIfNoItemsInCart = (context) => {
    const {
        totals: {
            items = []
        },
        isCartLoading,
        showInfoNotification,
        history
    } = context.props;

    const { checkoutStep, orderID } = context.state;

    if (
        (!isCartLoading && !items.length)
        || (checkoutStep === DETAILS_STEP && !orderID)
    ) {
        if (checkoutStep !== DETAILS_STEP) {
            showInfoNotification(__('Please add at least one product to cart!'));
        }

        if (!(orderID && checkoutStep === DETAILS_STEP)) {
            history.push(appendWithStoreCode('/cart'));
        }
    }
};

const handleRedirectIfLessThanMinAmountInCart = (context) => {
    const {
        minimumOrderAmount: { minimum_order_amount_reached = true },
        history
    } = context.props;

    const { checkoutStep } = context.state;

    if (!minimum_order_amount_reached && checkoutStep !== DETAILS_STEP) {
        history.push(appendWithStoreCode('/cart'));
    }
};

const componentDidMount = (args, callback, instance) => {
    const {
        history,
        guest_checkout,
        updateMeta,
        isGuestNotAllowDownloadable
    } = instance.props;

    const { email } = instance.state;

    handleRedirectIfLessThanMinAmountInCart(instance);
    handleRedirectIfNoItemsInCart(instance);

    // if guest checkout is disabled and user is not logged in => throw him to homepage
    if (!guest_checkout && !isSignedIn()) {
        history.push(appendWithStoreCode('/'));
    }
    // if guest is not allowed to checkout with downloadable => redirect to login page
    if (!isSignedIn() && isGuestNotAllowDownloadable) {
        instance.handleRedirectIfDownloadableInCart();
    }
    if (email) {
        instance.checkEmailAvailability(email);
    }
    updateMeta({ title: __('Checkout') });
};

export default {
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': {
            componentDidMount
        }
    }

};
