/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from '@scandipwa/scandipwa/src/util/Query';

const _getMinimumOrderAmountFields = () => [
    'minimum_order_amount_reached',
    'minimum_order_description'
];
const _getMinimumOrderAmount = () => (
    new Field('minimum_order_amount')
        .addFieldList(_getMinimumOrderAmountFields()));

const _getCartTotalsFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    _getMinimumOrderAmount()
];

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getCartTotalsFields
        }
    }
};
