/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    addBlockReferenceAfterWrapper,
    addBlockReferenceBefore,
    addBlockReferenceBeforeWrapper
} from '../../component/LayoutUpdate';
import {
    BR_MAIN_CONTENT_AREA,
    BR_MAIN_CONTENT_ASIDE,
    BR_MAIN_CONTENT_BOTTOM,
    BR_MAIN_CONTENT_CONTAINER,
    BR_MAIN_CONTENT_TOP
} from '../../util/blocks';

const pluginsForRender = [
    addBlockReferenceBefore(BR_MAIN_CONTENT_CONTAINER),
    addBlockReferenceBeforeWrapper(BR_MAIN_CONTENT_TOP),
    addBlockReferenceBeforeWrapper(BR_MAIN_CONTENT_AREA),
    addBlockReferenceAfterWrapper(BR_MAIN_CONTENT_BOTTOM),
    addBlockReferenceAfterWrapper(BR_MAIN_CONTENT_ASIDE)
];

// Note, plugin for Route/UrlRewrites/Component is missing,
// we expect other routes to take over

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/CmsPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/ConfirmAccountPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/ContactPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/CreateAccount/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/ForgotPassword/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/HomePage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/LoginAccount/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/MenuPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/MyAccount/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/NoMatch/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/NoMatchHandler/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/PasswordChangePage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/ProductComparePage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/SearchPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/SomethingWentWrong/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/StyleGuidePage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    },
    'Route/WishlistSharedPage/Component': {
        'member-function': {
            render: pluginsForRender
        }
    }
};
