/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';

export const GoogleAddressContext = createContext({
    address: {},
    // ^^^ available_regions, is_state_required, postcode, city, street, country_id, region_string, region_id
    currentAddressKey: '',
    onGooglePlaceSelected: () => {}
});

/** @namespace Scandiweb/GoogleAddressSuggestions/Context/GoogleAddress/getRandomId */
export const getRandomId = () => (
    // eslint-disable-next-line no-magic-numbers
    Math.random().toString(36).slice(2, 7)
);

/** @namespace Scandiweb/GoogleAddressSuggestions/Context/GoogleAddress/getLatinOnlyString */
export const getLatinOnlyString = (str) => (str
    ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : undefined
);

/** @namespace Scandiweb/GoogleAddressSuggestions/Context/GoogleAddress/GoogleAddressProvider */
export const GoogleAddressProvider = ({ children }) => {
    const countries = useSelector((state) => state.ConfigReducer.countries);
    const [address, setAddress] = useState({});
    const [currentAddressKey, setCurrentAddressKey] = useState(getRandomId());

    const onGooglePlaceSelected = (place) => {
        const components = {};

        place.address_components.forEach((address_component) => {
            address_component.types.forEach((type) => {
                components[type] = address_component.long_name;
            });
        });

        const {
            country,
            administrative_area_level_1: region_1,
            locality: region_2,
            sublocality_level_1: region_3,
            locality: city_1,
            postal_town: city_2,
            postal_code: postcode,
            route,
            street_number
        } = components;

        const address = {
            // vvv Always update the address key, to ensure fields update
            postcode: postcode || '',
            city: city_1 || city_2 || ''
        };

        if (route) {
            address.street = street_number
                ? [`${street_number} ${route}`]
                : [route];
            // ^^^ set STREET
        }

        const countryObject = countries.find((aCountry) => (
            getLatinOnlyString(aCountry.label) === getLatinOnlyString(country)
        ));

        if (!countryObject) {
            // ^^^ Can not determine country, skip
            setAddress(address);
            return;
        }

        address.available_regions = countryObject.available_regions || [];
        address.is_state_required = countryObject.is_state_required || true;
        address.country_id = countryObject.id;
        // ^^^ set COUNTRY

        const regionObject = countryObject.available_regions && countryObject.available_regions.find(
            (aRegion) => {
                const latinRegion = getLatinOnlyString(aRegion.name);

                return (
                    latinRegion.includes(getLatinOnlyString(region_1))
                    || latinRegion.includes(getLatinOnlyString(region_2))
                    || latinRegion.includes(getLatinOnlyString(region_3))
                );
            }
        );

        address.region_string = region_1 || region_2 || region_3;
        // ^^^ set REGION

        if (regionObject) {
            address.region_id = regionObject.id;
            // ^^^ set REGION ID
        }

        setAddress(address);
    };

    useEffect(() => {
        setCurrentAddressKey(getRandomId());
        // ^^^ separate from address, after address
        // this is needed because otherwise components re-render
        // before default address ahs time to change
    }, [address]);

    const contextValue = {
        address,
        currentAddressKey,
        // ^^^ will handle: address, currentAddressKey
        onGooglePlaceSelected
    };

    return (
        <GoogleAddressContext.Provider value={ contextValue }>
            { children }
        </GoogleAddressContext.Provider>
    );
};

GoogleAddressProvider.displayName = 'GoogleAddressProvider';

GoogleAddressProvider.propTypes = {
    children: ChildrenType.isRequired
};
