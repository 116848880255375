/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActivePopup } from 'Store/Overlay/Overlay.action';

import { AmastyItemsType } from '../../type/AmastyPromo.type';
import { AMASTY_FREE_GIFT_POPUP } from '../AmastyGiftAdd/AmastyGiftAdd.config';
import AmastyGiftPopup from './AmastyGiftPopup.component';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amItems: state.ConfigReducer?.amItems
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideMyPopup: (popupKey, payload) => dispatch(hideActivePopup(popupKey, payload))
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftPopup/Container */
export class AmastyGiftPopupContainer extends PureComponent {
    static propTypes = {
        amItems: AmastyItemsType.isRequired,
        hideMyPopup: PropTypes.func.isRequired,
        handlePopupClose: PropTypes.func.isRequired
    };

    containerFunctions = {
        handlePopupClose: this.handlePopupClose.bind(this)
    };

    handlePopupClose() {
        const { hideMyPopup } = this.props;

        hideMyPopup(AMASTY_FREE_GIFT_POPUP);
    }

    containerProps() {
        const {
            amItems
        } = this.props;

        return {
            amItems
        };
    }

    render() {
        return (
            <AmastyGiftPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyGiftPopupContainer);
