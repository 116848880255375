/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    addBlockReferenceAfter,
    addBlockReferenceBefore,
    addBlockReferenceBeforeWrapper
} from '../../component/LayoutUpdate';
import {
    BR_ALERT_URLS,
    BR_PRODUCT_AUXILIARY_INFO,
    BR_PRODUCT_EXTRA_INFO,
    BR_PRODUCT_INFO_AUXILIARY_CONTAINER,
    BR_PRODUCT_SOCIAL_LINKS_CONTAINER,
    BR_PRODUCT_VIEW_EXTRA_HINT
} from '../../util/blocks';

const renderBeforeBrandOnMobile = (blockReference) => (args, callback, instance) => {
    const { device: { isMobile } = {} } = instance.props;

    if (!isMobile) {
        return callback(...args);
    }

    return addBlockReferenceBefore(blockReference)(args, callback);
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderPriceWithSchema: addBlockReferenceBeforeWrapper(BR_PRODUCT_INFO_AUXILIARY_CONTAINER),
            renderAddToCartActionBlock: [
                addBlockReferenceBefore(BR_PRODUCT_VIEW_EXTRA_HINT),
                addBlockReferenceBefore(BR_PRODUCT_EXTRA_INFO),
                addBlockReferenceAfter(BR_PRODUCT_SOCIAL_LINKS_CONTAINER)
            ],
            renderAddToCartMobile: addBlockReferenceBefore(BR_PRODUCT_SOCIAL_LINKS_CONTAINER),
            renderBrand: [
                renderBeforeBrandOnMobile(BR_PRODUCT_VIEW_EXTRA_HINT),
                renderBeforeBrandOnMobile(BR_PRODUCT_EXTRA_INFO)
            ],
            renderProductAlerts: addBlockReferenceBeforeWrapper(BR_ALERT_URLS),
            renderSkuAndStock: addBlockReferenceBeforeWrapper(BR_PRODUCT_AUXILIARY_INFO)
        }
    }
};
