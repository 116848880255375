import {
    CHOOSE_CUSTOM_DELIVERY_METHOD,
    CHOOSE_HOME_DELIVERY_METHOD,
    CHOOSE_OTHER_DELIVERY_METHOD
} from 'Store/Delivery/Delivery.action';

/** @namespace Scandipwa/Store/Delivery/Reducer/getInitialState */
export const getInitialState = () => ({
    isChosen: 'home'
});

/** @namespace Scandipwa/Store/Delivery/Reducer/DeliveryReducer */
export const DeliveryReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case CHOOSE_CUSTOM_DELIVERY_METHOD:

        return {
            ...state,
            isChosen: 'custom'
        };
    case CHOOSE_OTHER_DELIVERY_METHOD:
        return {
            ...state,
            isChosen: 'other'
        };
    case CHOOSE_HOME_DELIVERY_METHOD:
        return {
            ...state,
            isChosen: 'home'
        };
    default:
        return state;
    }
};

export default DeliveryReducer;
