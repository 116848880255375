import { FIELD_TYPE as FIELD_TYPE_ADDRESS } from 'SourceComponent/Field/Field.config';

/**
 * Overridden to add 'google-address' field type
 * */
export const FIELD_TYPE = {
    ...FIELD_TYPE_ADDRESS,
    address: 'google-address',
    none: 'none'
};

export default FIELD_TYPE;
