/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import './Field.override.style';

/** @namespace HotmeTheme/Component/Field/Component */
export class FieldComponent extends SourceField {
    /**
     * Overridden to add span wrapping the label
     * Without it, can't style disabled labels
     */
    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  { ...attr }
                  { ...inputEvents }
                />
                <div block="input-control" />
                <span>{ label }</span>
            </label>
        );
    }

    /**
     * Overridden to remove space between
     * the label and the required tag
     */
    renderRequiredTag() {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={ { isRequired: true } }>
                *
            </span>
        );
    }
}

export default FieldComponent;
