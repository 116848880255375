import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

/**
 * Overridden all methods to remove login/register functionality
 */
/** @namespace HotmeTheme/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerData() {
        return false;
    }

    logout() {
        return false;
    }

    forgotPassword() {
        return false;
    }

    resetPassword() {
        return false;
    }

    createAccount() {
        return false;
    }

    confirmAccount() {
        return false;
    }

    async signIn() {
        return false;
    }

    handleForceRedirectToLoginPage() {
        return false;
    }

    handleCustomerDataOnInit() {
        return false;
    }
}

export default new MyAccountDispatcher();
