/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyFreeGift/Query/AmastyItems/Query */
export class AmastyItemsQuery {
    getQuery(cartId) {
        return new Field('amItems')
            .addArgument('cartId', 'String!', cartId)
            .addFieldList(this.getFields());
    }

    getFields() {
        return [
            'buttonName',
            'isShowPrice',
            'isGiftsCounter',
            'isSelectionMethod',
            this.getProductField()
        ];
    }

    getProductField() {
        return new Field('products')
            .addFieldList(ProductListQuery._getProductInterfaceFields());
    }
}

export default new AmastyItemsQuery();
