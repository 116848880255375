/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AddressUpdate } from '../../component/AddressUpdate';

const MANAGED_FIELD_FORMS = ['streetGroup', 'addressGroup'];

const wrapWithKeyToReRender = (args, callback) => {
    const [section] = args;
    const {
        attr: { name = '' } = {},
        name: sectionName
    } = section;

    const realName = name || sectionName;

    // We only want to re-render the section "streetGroup" and "addressGroup"
    if (MANAGED_FIELD_FORMS.indexOf(realName) !== -1) {
        return (
            <AddressUpdate>
                { callback(...args) }
            </AddressUpdate>
        );
    }

    return callback(...args);
};

const updateStateFromAddress = (args, _callback, instance) => {
    const [prevProps] = args;
    const { address } = instance.props;
    const { address: prevAddress } = prevProps;

    if (address === prevAddress) {
        return;
    }

    const mapStateToAddress = {
        // street: XXX,
        postcode: 'currentZipcode',
        city: 'currentCity',
        country_id: 'countryId',
        region_string: 'currentRegion',
        region_id: 'currentRegionId',
        is_state_required: 'isStateRequired',
        available_regions: 'availableRegions'
    };

    const stateUpdate = Object.entries(mapStateToAddress).reduce(
        (acc, [addressKey, stateKey]) => {
            if (!address[addressKey]) {
                return acc;
            }

            return {
                ...acc,
                [stateKey]: address[addressKey]
            };
        },
        {}
    );

    instance.setState(stateUpdate);
};

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            renderSection: wrapWithKeyToReRender
        }
    },
    'Component/CheckoutAddressForm/Container': {
        'member-function': {
            componentDidUpdate: updateStateFromAddress
        }
    }
};
