/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { LockIcon } from '@scandipwa/scandipwa/src/component/LockIcon/LockIcon.component';
import PropTypes from 'prop-types';

const propTypes = (originalMember) => ({
    ...originalMember,
    minimumOrderAmountReached: PropTypes.bool
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    minimumOrderAmountReached: true
});

const renderSecureCheckoutButton = (args, callback, instance) => {
    const {
        handleCheckoutClick,
        minimumOrderAmountReached,
        hasOutOfStockProductsInCart
    } = instance.props;

    return (
        <button
          block="CartOverlay"
          elem="CheckoutButton"
          mix={ { block: 'Button' } }
          onClick={ handleCheckoutClick }
          disabled={ hasOutOfStockProductsInCart || !minimumOrderAmountReached }
        >
            <LockIcon />
                { __('Secure checkout') }
        </button>
    );
};

export default {
    'Component/CartOverlay/Component': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': {
            renderSecureCheckoutButton
        }
    }
};
