export const CHOOSE_CUSTOM_DELIVERY_METHOD = 'CHOOSE_CUSTOM_DELIVERY_METHOD';

export const CHOOSE_OTHER_DELIVERY_METHOD = 'CHOOSE_OTHER_DELIVERY_METHOD';

export const CHOOSE_HOME_DELIVERY_METHOD = 'CHOOSE_HOME_DELIVERY_METHOD';

/** @namespace Scandipwa/Store/Delivery/Action/isCustomDeliveryChosen */
export const isCustomDeliveryChosen = (isChosen) => ({
    type: CHOOSE_CUSTOM_DELIVERY_METHOD,
    isChosen
});

/** @namespace Scandipwa/Store/Delivery/Action/isOtherDeliveryChosen */
export const isOtherDeliveryChosen = (isChosen) => ({
    type: CHOOSE_OTHER_DELIVERY_METHOD,
    isChosen
});

/** @namespace Scandipwa/Store/Delivery/Action/isHomeDeliveryChosen */
export const isHomeDeliveryChosen = (isChosen) => ({
    type: CHOOSE_HOME_DELIVERY_METHOD,
    isChosen
});
