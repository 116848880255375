/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { GOOGLE_ADDRESS_TYPE, GoogleAddress } from '../../component/GoogleAddress';

const addGoogleAddressTypeToRenderMap = (member, instance) => ({
    ...member,
    [GOOGLE_ADDRESS_TYPE]: () => (
        <GoogleAddress addressFieldProps={ instance.props } />
    )
});

export default {
    'Component/Field/Component': {
        'member-property': {
            renderMap: addGoogleAddressTypeToRenderMap
        }
    }
};
