import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace HotmeTheme/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    // TODO implement logic
}

export default FieldSelectComponent;
