/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyFreeGift/Query/AmastyAdd/Query */
export class AmastyAddQuery {
    getQuery() {
        return new Field('amAdd')
            .addFieldList(this.getFields());
    }

    getFields() {
        return [
            'promoSku',
            'commonQty',
            'popupLink',
            'popupName',
            'isHasItems',
            'isOpenAuto',
            'isCountersMode'
        ];
    }
}

export default new AmastyAddQuery();
