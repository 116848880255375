import MenuItem from 'Component/MenuItem';
import { MenuComponent as HotmeMenuComponent } from 'HotmeComponent/Menu/Menu.component';
import { scrollToTop } from 'Util/Browser';
import { getSortedItems } from 'Util/Menu';

import './Menu.override.style';

/** @namespace Scandipwa/Component/Menu/Component */
export class MenuComponent extends HotmeMenuComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        super.componentDidMount();

        scrollToTop();
    }

    // override to render different root as top-level between desktop and mobile
    renderTopLevel() {
        const { device } = this.props;

        if (device.isMobile) {
            return this.renderTopLevelMobile();
        }

        return this.renderTopLevelDesktop();
    }

    renderTopLevelMobile() {
        const { menu } = this.props;

        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        const allSexToysChildIndex = childrenArray.findIndex((child) => child.title === 'All Sex Toys');

        if (allSexToysChildIndex !== -1) {
            // If 'All Sex Toys' child exists, move it to the top of the childrenArray
            const allSexToysChild = childrenArray.splice(allSexToysChildIndex, 1)[0];
            childrenArray.unshift(allSexToysChild);
        }

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    { this.renderAdditionalInformation(true) }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel.bind(this)) }
                    </ul>
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }

    renderTopLevelDesktop() {
        const { menu } = this.props;

        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        categoryArray.shift();
        const mainCategoriesTitle = categoryArray[0].title;
        const childrenArray = getSortedItems(Object.values(categoryArray));

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    { this.renderAdditionalInformation(true) }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel.bind(this)) }
                    </ul>
                </div>
                { this.renderSubMenuDesktop(categoryArray) }
            </>
        );
    }

    /**
     * Overridden to keep menu items in DOM for crawlers
     */
    renderSubMenuDesktopItems(item) {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                    { this.renderAdditionalInformation() }
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    }

    /**
     * Overriden to not render All when it's Sex Toys
     */
    renderSubLevel(category, isSecondLevel = false) {
        const { activeMenuItemsStack, device } = this.props;
        const { item_id, children, title } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        const unallowedTitles = [
            'Sex Toys'
        ];

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...subcategoryMods } }
                >
                    { device.isMobile && !unallowedTitles.includes(title) && (
                        <MenuItem
                          activeMenuItemsStack={ activeMenuItemsStack }
                          item={ { ...category, title: __('All %s', title) } }
                          itemMods={ { ...subcategoryMods, isSecondLevel } }
                          isLink
                        />
                    ) }
                    { childrenArray.map((item) => this.renderSubLevelItems(item, isSecondLevel)) }
                </div>
            </div>
        );
    }
}

export default MenuComponent;
