/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { LockIcon } from '@scandipwa/scandipwa/src/component/LockIcon/LockIcon.component';
import PropTypes from 'prop-types';

const propTypes = (originalMember) => ({
    ...originalMember,
    minimumOrderAmountReached: PropTypes.bool,
    minimumOrderDescription: PropTypes.string
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    minimumOrderAmountReached: true,
    minimumOrderDescription: ''
});

const renderSecureCheckoutButton = (args, callback, instance) => {
    const {
        onCheckoutButtonClick,
        minimumOrderDescription,
        minimumOrderAmountReached,
        hasOutOfStockProductsInCart
    } = instance.props;

    if (hasOutOfStockProductsInCart) {
        return (
            <div block="CartPage" elem="OutOfStockProductsWarning">
                { __('Please, remove out of stock products from cart') }
            </div>
        );
    }

    if (!minimumOrderAmountReached) {
        return (
            <div block="CartPage" elem="OutOfStockProductsWarning">
                { minimumOrderDescription }
            </div>
        );
    }

    return (
        <div block="CartPage" elem="CheckoutButtonWrapper">
            <button
              block="CartPage"
              elem="CheckoutButton"
              mix={ { block: 'Button' } }
              onClick={ onCheckoutButtonClick }
            >
                <LockIcon />
                { __('Proceed to checkout') }
            </button>
        </div>
    );
};

export default {
    'Route/CartPage/Component': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': {
            renderSecureCheckoutButton
        }
    }
};
