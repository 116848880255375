/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import AmastyAddQuery from '../../query/AmastyAdd.query';
import AmastyItemsQuery from '../../query/AmastyItems.query';
import { updateAmastyPromoData } from '../../store/Config/AmastyPromo.action';
import { stripHtml } from '../../util/Amasty/AmastyPromo';

const addProductToCart = async (args, callback) => {
    const [dispatch] = args;

    await callback(...args);

    const {
        amAdd,
        am_notification_text,
        am_display_notification
    } = getStore().getState().ConfigReducer;

    const isDisplayNotification = am_display_notification === '1';

    if (!isDisplayNotification) {
        return;
    }

    const { isHasItems } = amAdd;

    if (!isHasItems) {
        return;
    }

    dispatch(showNotification('info', stripHtml(am_notification_text)));
};

const _updateCartData = async (args, callback) => {
    const [cartData, dispatch] = args;
    const { id: cartId } = cartData;

    if (!cartId) {
        return callback(...args);
    }

    await callback(...args);

    const { amAdd, amItems } = await fetchQuery([
        AmastyAddQuery.getQuery(),
        AmastyItemsQuery.getQuery(cartId)
    ]);

    return dispatch(updateAmastyPromoData(amAdd, amItems));
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData,
            addProductToCart
        }
    }
};
