import { Suspense } from 'react';

import {
    CategoryPage as SourceCategoryPage,
    CmsPage as SourceCmsPage,
    NoMatch as SourceNoMatch,
    ProductPage as SourceProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';

export const ProductPage = SourceProductPage;
export const CategoryPage = SourceCategoryPage;
export const CmsPage = SourceCmsPage;
export const NoMatch = SourceNoMatch;

/** @namespace Scandipwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewrites {
    renderProductPage() {
        const { props } = this.props;
        const {
            history,
            location,
            match,
            productSKU,
            id
        } = props;

        if (!productSKU) {
            return this.renderDefaultPage();
        }

        return (
                <ProductPage
                  history={ history }
                  location={ location }
                  match={ match }
                  productSKU={ productSKU }
                  productID={ id }
                  key={ id }
                />
        );
    }

    renderCmsPage() {
        const { props } = this.props;
        const {
            history,
            location,
            match,
            pageIds
        } = props;

        return (
            <CmsPage
              history={ history }
              location={ location }
              match={ match }
              pageIds={ pageIds }
            />
        );
    }

    renderCategoryPage() {
        const { props } = this.props;
        const {
            history,
            location,
            match,
            categoryIds
        } = props;

        return (
            <CategoryPage
              history={ history }
              location={ location }
              match={ match }
              categoryIds={ categoryIds }
            />
        );
    }

    renderNoMatch() {
        const { props } = this.props;
        const {
            history,
            location,
            match
        } = props;

        return (
        <NoMatch
          history={ history }
          location={ location }
          match={ match }
        />
        );
    }

    renderDefaultPage() {
        return (
            <main />
        );
    }

    renderContent() {
        const { type } = this.props;

        switch (type) {
        case TYPE_PRODUCT:
            return this.renderProductPage();
        case TYPE_CMS_PAGE:
            return this.renderCmsPage();
        case TYPE_CATEGORY:
            return this.renderCategoryPage();
        case TYPE_NOTFOUND:
            return this.renderNoMatch();
        default:
            return this.renderDefaultPage();
        }
    }

    render() {
        return (
            <Suspense fallback={ this.renderDefaultPage() }>
                { this.renderContent() }
            </Suspense>
        );
    }
}

export default UrlRewritesComponent;
