/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addGoogleAddressFields = (args, callback) => ([
    ...callback(...args),
    'google_address_enabled',
    'google_address_api_key'
]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addGoogleAddressFields
        }
    }
};
