import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { showNotification } from 'Store/Notification/Notification.action';

/** @namespace Scandipwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    __construct(props) {
        super.__construct(props);
    }

    componentDidMount() {
        this.requestUrlRewrite();

        this.initialUrl = location.pathname;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
