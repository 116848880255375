import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace HotmeTheme/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    /**
     * Overridden to add new_from_date and new_to_date fields to product.
     */
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);

        fields.push('new_from_date', 'new_to_date');

        return fields;
    }

    /**
     * Overridden to add price range info fields to cart item data.
     */
    _getCartProductInterfaceFields() {
        return [
            ...super._getCartProductInterfaceFields(),
            this._getPriceRangeField()
        ];
    }

    /**
     * Overridden to add price range info fields to cart item variant data.
     */
    _getCartProductField() {
        return new Field('product')
            .addFieldList([
                'id',
                'sku',
                'stock_status',
                'salable_qty',
                this._getPriceRangeField(),
                this._getStockItemField(),
                this._getProductThumbnailField(),
                this._getAttributesField(true, true)
            ]);
    }
}

export default new ProductListQuery();
