/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';

/** @namespace HotmeTheme/Component/Product/Component */
export class Product extends SourceProduct {
    /**
     * Overridden to remove wishlist and compare buttons
     */
    renderWishlistButton() {
        return null;
    }

    renderCompareButton() {
        return null;
    }
}

export default Product;
