/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';

import { useBlockReference, useLayoutUpdate } from '../hooks/useLayoutUpdate';
import { LayoutUpdateType } from '../type/LayoutUpdate.type';
import Widget from './Widget';

/** @namespace Scandiweb/LayoutUpdates/Component/LayoutUpdate/LayoutUpdate */
export const LayoutUpdate = ({ layoutUpdate }) => {
    const { instance_id } = layoutUpdate;
    const isRenderLU = useLayoutUpdate(layoutUpdate);
    // eslint-disable-next-line no-console
    console.log('>>>>>>', instance_id);
    if (!isRenderLU) {
        return null;
    }

    return (
        <Widget id={ instance_id } />
    );
};

LayoutUpdate.propTypes = {
    layoutUpdate: LayoutUpdateType.isRequired
};

LayoutUpdate.displayName = 'LayoutUpdate';

/** @namespace Scandiweb/LayoutUpdates/Component/LayoutUpdate/LayoutUpdates */
export const LayoutUpdates = ({ blockReference }) => {
    const layoutUpdates = useBlockReference(blockReference);

    return layoutUpdates.map((layoutUpdate) => {
        const { page_id } = layoutUpdate;

        return (
            <LayoutUpdate
              key={ page_id }
              layoutUpdate={ layoutUpdate }
            />
        );
    });
};

LayoutUpdates.propTypes = {
    blockReference: PropTypes.string.isRequired
};

LayoutUpdates.displayName = 'LayoutUpdates';

/** @namespace Scandiweb/LayoutUpdates/Component/LayoutUpdate/addBlockReferenceAfter */
export const addBlockReferenceAfter = (blockReference) => (args, callback) => (
    <>
        { callback(...args) }
        <LayoutUpdates blockReference={ blockReference } />
    </>
);

/** @namespace Scandiweb/LayoutUpdates/Component/LayoutUpdate/addBlockReferenceBefore */
export const addBlockReferenceBefore = (blockReference) => (args, callback) => (
    <>
        <LayoutUpdates blockReference={ blockReference } />
        { callback(...args) }
    </>
);

/** @namespace Scandiweb/LayoutUpdates/Component/LayoutUpdate/addBlockReferenceBeforeWrapper */
export const addBlockReferenceBeforeWrapper = (blockReference) => (args, callback) => {
    const wrapper = callback(...args);

    if (!wrapper) {
        return addBlockReferenceBefore(blockReference)(args, callback);
    }

    return cloneElement(wrapper, wrapper.props, Children.map(wrapper.props.children, (child, i) => {
        if (i !== 0) {
            return child;
        }

        return (
            <>
                <LayoutUpdates blockReference={ blockReference } />
                { child }
            </>
        );
    }));
};

/** @namespace Scandiweb/LayoutUpdates/Component/LayoutUpdate/addBlockReferenceAfterWrapper */
export const addBlockReferenceAfterWrapper = (blockReference) => (args, callback) => {
    const wrapper = callback(...args);

    if (!wrapper) {
        return addBlockReferenceAfter(blockReference)(args, callback);
    }

    return cloneElement(wrapper, wrapper.props, Children.map(wrapper.props.children, (child, i) => {
        if (i !== wrapper.props.children.length - 1) {
            return child;
        }

        return (
            <>
                { child }
                <LayoutUpdates blockReference={ blockReference } />
            </>
        );
    }));
};
