/**
 * PageBuilder slider for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addPagebuilderConfigToRequest = (args, callback) => (Array.from(callback(...args)));

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addPagebuilderConfigToRequest
        }
    }
};
