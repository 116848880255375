/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer
} from 'Component/Product/Product.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { ProductType } from 'Type/ProductList.type';
import { getSmallImage } from 'Util/Product/Extract';
import { fetchQuery } from 'Util/Request';

import { AmastyFreeGiftContext } from '../../context/AmastyFreeGift';
import AmastyPromoQuery from '../../query/AmastyPromo.query';
import { getGiftItemPrice } from '../../util/Amasty/Discount';
import { AMASTY_FREE_GIFT_POPUP } from '../AmastyGiftAdd/AmastyGiftAdd.config';
import AmastyGiftItem from './AmastyGiftItem.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cartId: state.CartReducer?.cartTotals?.id
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    hideMyPopup: (popupKey, payload) => dispatch(hideActivePopup(popupKey, payload))
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftItem/Container */
export class AmastyGiftItemContainer extends ProductContainer {
    static propTypes = {
        product: ProductType.isRequired,
        buttonName: PropTypes.string.isRequired,
        isSelectionMethod: PropTypes.bool.isRequired,
        cartId: PropTypes.string.isRequired,
        hideMyPopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired
    };

    static contextType = AmastyFreeGiftContext;

    containerFunctions = {
        ...this.containerFunctions,
        handleAddToCart: this.handleAddToCart.bind(this),
        handleQtyChange: this.handleQtyChange.bind(this),
        handleCheckboxChange: this.handleCheckboxChange.bind(this),
        getIsGiftSelectable: this.getIsGiftSelectable.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            giftQty: this.getSelectedGiftQty(),
            isSelected: this.getIsGiftSelected()
        };
    }

    async handleAddToCart() {
        const {
            product: { sku },
            cartId,
            hideMyPopup,
            showNotification,
            updateInitialCartData
        } = this.props;

        if (!cartId) {
            return;
        }

        if (!this.getIsGiftSelectable()) {
            showNotification('info', __('You need to choose options for your item.'));
            return;
        }

        const options = this.getProductOptions();

        const giftData = [{
            sku,
            giftQty: 1,
            options
        }];
        // ^^^ BE handles data as an array so we should use it here for single item too.

        await fetchQuery([AmastyPromoQuery.getQuery(cartId, giftData)]).then(
        /** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftItem/Container/AmastyGiftItemContainer/handleAddToCart/fetchQuery/then */
            ({ amPromoAdd }) => {
                amPromoAdd.forEach((promo) => {
                    const { status, message } = promo;
                    showNotification(status, message);
                });
            }
        );

        hideMyPopup(AMASTY_FREE_GIFT_POPUP);
        updateInitialCartData();
    }

    handleInitialQty() {
        const {
            giftQty
        } = this.state;

        if (giftQty) {
            return;
        }

        this.setState({
            giftQty: 1
        });
    }

    handleCheckboxChange() {
        const {
            product: { sku }
        } = this.props;
        const {
            isSelected
        } = this.state;
        const {
            handleSelectItem,
            handleRemoveItem
        } = this.context;

        if (!isSelected) {
            this.handleInitialQty();

            const options = this.getProductOptions();
            handleSelectItem(sku, options);
        } else {
            this.setState({
                giftQty: 0
            });
            handleRemoveItem(sku);
        }

        this.setState(({ isSelected }) => ({ isSelected: !isSelected }));
    }

    handleQtyChange(qty) {
        const {
            product: { sku },
            handleChangeItemQty
        } = this.props;
        const {
            isSelected
        } = this.state;
        const {
            handleSelectItem,
            handleRemoveItem
        } = this.context;

        this.setState({
            giftQty: qty
        });

        if (qty <= 0) {
            this.setState({ isSelected: false });

            handleRemoveItem(sku);
            return;
        }

        if (!isSelected) {
            this.setState({ isSelected: true });

            const options = this.getProductOptions();
            handleSelectItem(sku, options);
            return;
        }

        handleChangeItemQty(sku, qty);
    }

    calculateNewPrice() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        final_price: {
                            value
                        }
                    }
                }
            },
            giftDetails
        } = this.props;

        return getGiftItemPrice(value, giftDetails);
    }

    getIsGiftSelectable() {
        const {
            product: { type_id: type }
        } = this.props;
        const {
            giftQty,
            selectedProduct
        } = this.state;
        const {
            giftsLeft
        } = this.context;

        if (type === PRODUCT_TYPE.configurable && !selectedProduct) {
            return false;
        }

        //     check if there are any gift qty left
        // vvv or if it's possible to decrease qty for already selected item
        return giftsLeft > 0 || giftQty > 0;
    }

    getIsGiftSelected() {
        const {
            product: { sku }
        } = this.props;
        const {
            selectedItems
        } = this.context;

        return selectedItems.some((item) => item.sku === sku);
    }

    getSelectedGiftQty() {
        const {
            product: { sku }
        } = this.props;
        const {
            selectedItems
        } = this.context;

        const { giftQty = 0 } = selectedItems.find((item) => item.sku === sku) || {};

        return giftQty;
    }

    getMaxGiftQty() {
        const { giftQty } = this.state;
        const { giftsLeft } = this.context;

        return giftQty + giftsLeft;
    }

    getProductOptions() {
        const {
            product: {
                configurable_options
            }
        } = this.props;
        const {
            parameters
        } = this.state;

        const parameterKeys = Object.keys(parameters);

        // vvv creating a custom options object which resembles the one that original extension used
        const options = parameterKeys.map((parameterKey) => {
            const { attribute_id } = configurable_options[parameterKey];
            const attribute_value = parameters[parameterKey];

            return {
                id: attribute_id,
                value: attribute_value
            };
        });

        return options;
    }

    containerProps() {
        const {
            product,
            giftDetails,
            amItems: {
                buttonName,
                isShowPrice,
                isGiftsCounter,
                isSelectionMethod
            }
        } = this.props;
        const {
            giftQty,
            isSelected
        } = this.state;
        const {
            giftsLeft
        } = this.context;

        return {
            ...super.containerProps(),
            product,
            buttonName,
            giftQty,
            giftDetails,
            giftPrice: this.calculateNewPrice(),
            giftsLeft,
            maxGiftQty: this.getMaxGiftQty(),
            isSelected,
            isShowPrice,
            isGiftsCounter,
            isSelectionMethod,
            thumbnail: getSmallImage(product)
        };
    }

    render() {
        return (
            <AmastyGiftItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyGiftItemContainer);
