/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AmastyGiftAdd from '../../component/AmastyGiftAdd';

const addFreeGiftButton = (args, callback) => (
    <>
        <AmastyGiftAdd />
        { callback(args) }
    </>
);

export default {
    'Component/Menu/Component': {
        'member-function': {
            render: addFreeGiftButton
        }
    }
};
