/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { cloneElement } from 'react';

import AmastyGiftMessage from '../../component/AmastyGiftMessage';

const addAmCartMessageToMapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        amCartMessage: state.ConfigReducer.am_cart_message
    };
};

const containerProps = (args, callback, instance) => {
    const { amCartMessage } = instance.props;

    return {
        ...callback(...args),
        amCartMessage
    };
};

const renderTitle = (args, callback, instance) => {
    const {
        item: {
            ampromo_rule_id
        } = {},
        amCartMessage
    } = instance.props;

    const result = callback(...args);

    if (!ampromo_rule_id) {
        return result;
    }

    return (
        <>
            { cloneElement(result, { ...result.props }, [
                ...result.props.children,
                <AmastyGiftMessage
                  amCartMessage={ amCartMessage }
                />
            ]) }
        </>
    );
};

const renderProductName = (args, callback, instance) => {
    const {
        item: {
            name,
            ampromo_rule_id
        }
    } = instance.props;

    if (!ampromo_rule_id) {
        return callback(...args);
    }

    return (
        <p
          block="CartItem"
          elem="Heading"
        >
            { name }
        </p>
    );
};

export default {
    'Component/CartItem/Container/mapStateToProps': {
        function: addAmCartMessageToMapStateToProps
    },
    'Component/CartItem/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/CartItem/Component': {
        'member-function': {
            renderTitle,
            renderProductName
        }
    }
};
