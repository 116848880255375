/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useContext } from 'react';

import { ChildrenType } from 'Type/Common.type';

import { GoogleAddressContext } from '../context/GoogleAddress';

/** @namespace Scandiweb/GoogleAddressSuggestions/Component/AddressUpdate/AddressUpdate */
export const AddressUpdate = ({ children }) => {
    const { currentAddressKey } = useContext(GoogleAddressContext);

    return (
        // vvv We need to re-render fields to force new default values
        <div key={ currentAddressKey }>
            { children }
        </div>
    );
};

AddressUpdate.displayName = 'AddressUpdate';

AddressUpdate.propTypes = {
    children: ChildrenType.isRequired
};
