/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/AmastyPromo/getRemainingGiftsQty */
export const getRemainingGiftsQty = (commonQty, selectedItems) => {
    const selectedGiftsQty = selectedItems.reduce((sum, { giftQty }) => sum + giftQty, 0);

    return commonQty - selectedGiftsQty;
};

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/AmastyPromo/stripHtml */
export const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};
