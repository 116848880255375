import { createElement, lazy } from 'react';

import { SLIDER_CONTENT_TYPE, SLIDER_SKELETON } from 'Component/PageBuilderSlider/PageBuilderSlider.config';

const Slider = lazy(() => import('Component/PageBuilderSlider'));

const addReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: SLIDER_CONTENT_TYPE },
        replace: (domNode) => (
            createElement(Slider, {
                elements: instance.toReactElements(
                    [domNode],
                    SLIDER_SKELETON
                )
            })
        )
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
