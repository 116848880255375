import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore/index';
import DeliveryReducer from 'Store/Delivery/Delivery.reducer';

/** @namespace Scandipwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    DeliveryReducer
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}
