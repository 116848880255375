/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        minimumOrderAmount: state.CartReducer.cartTotals.minimum_order_amount
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    minimumOrderAmount: PropTypes.shape({
        minimum_order_amount_reached: PropTypes.bool,
        minimum_order_description: PropTypes.string
    })
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    minimumOrderAmount: {}
});

const containerProps = (args, callback, instance) => {
    const {
        minimumOrderAmount: {
            minimum_order_amount_reached: minimumOrderAmountReached = false
        }
    } = instance.props;

    const callbackProps = callback(...args);

    return { ...callbackProps, minimumOrderAmountReached };
};

export default {
    'Component/CartOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartOverlay/Container': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': { containerProps }
    }
};
