import {
    PRODUCT_TYPE as SOURCE_PRODUCT_TYPE
} from 'SourceComponent/Product/Product.config';

// TODO: implement PRODUCT_TYPE
export const PRODUCT_TYPE = SOURCE_PRODUCT_TYPE;

export default PRODUCT_TYPE;

export const NEW_LABEL_BLOCK = 'new-label';
