/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const _getCartItemsFields = (args, callback) => [
    ...callback(...args),
    'name',
    'ampromo_rule_id'
];

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getCartItemsFields
        }
    }
};
