/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import { WidgetType } from '../../type/Widget.type';

import './Widget.style';

/** @namespace Scandiweb/LayoutUpdates/Component/Widget/Component/WidgetComponent */
export class WidgetComponent extends PureComponent {
    static propTypes = {
        widget: WidgetType.isRequired
    };

    typeToRenderMap = {
        // Map widget types (class names) to their components
    };

    renderWidgetOfType() {
        const { widget, widget: { type } } = this.props;
        const TypeRenderer = this.typeToRenderMap[type];

        if (!TypeRenderer) {
            return null;
        }

        return (
            <TypeRenderer
              widget={ widget }
            />
        );
    }

    render() {
        return this.renderWidgetOfType();
    }
}

export default WidgetComponent;
