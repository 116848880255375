import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';

/** @namespace HotmeTheme/Component/Menu/Component */
export class MenuComponent extends SourceMenu {
    /**
     * Overridden to remove compare button
     */
    renderComparePageLink() {
        return null;
    }
}

export default MenuComponent;
