/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable fp/no-let */
export const HUNDRED = 100;

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/Discount/getPercentDiscount */
export const getPercentDiscount = (itemPrice, promoDiscount) => {
    const percent = parseFloat(promoDiscount.replace('%', '', promoDiscount));

    return (itemPrice * percent) / HUNDRED;
};

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/Discount/getFixedDiscount */
export const getFixedDiscount = (itemPrice, promoDiscount) => {
    let discount = Math.abs(promoDiscount);
    if (discount > itemPrice) {
        discount = itemPrice;
    }

    return discount;
};

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/Discount/getFixedPrice */
export const getFixedPrice = (itemPrice, promoDiscount) => {
    let discount = itemPrice - parseFloat(promoDiscount);
    if (discount < 0) {
        discount = 0;
    }

    return discount;
};

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/Discount/getDiscountAfterMinimalPrice */
export const getDiscountAfterMinimalPrice = (minimalPrice, itemPrice, discount) => {
    if (itemPrice > minimalPrice && itemPrice - discount < minimalPrice) {
        return itemPrice - minimalPrice;
    }

    return discount;
};

/** @namespace Scandiweb/AmastyFreeGift/Util/Amasty/Discount/getGiftItemPrice */
export const getGiftItemPrice = (itemPrice, discountDetails) => {
    let discount = 0;
    const {
        discount: {
            discount_item,
            minimal_price: minimalPrice
        }
    } = discountDetails;

    const promoDiscount = discount_item.toString();

    if (promoDiscount === '' || promoDiscount === '100%' || promoDiscount === null) {
        discount = itemPrice;
    } else if (promoDiscount.indexOf('%') !== -1) {
        discount = getPercentDiscount(itemPrice, promoDiscount);
    } else if (promoDiscount.indexOf('-') !== -1) {
        discount = getFixedDiscount(itemPrice, promoDiscount);
    } else {
        discount = getFixedPrice(itemPrice, promoDiscount);
    }

    discount = getDiscountAfterMinimalPrice(minimalPrice, itemPrice, discount);

    return itemPrice - discount;
};
