import { createPortal } from 'react-dom';

import Overlay from 'Component/Overlay/Overlay.component';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './PopupComponent.override.style';
/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    static defaultProps = {
        ...Overlay.defaultProps,
        clickOutside: false,
        title: ''
    };

    render() {
        const { mix, areOtherOverlaysOpen } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
