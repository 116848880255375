/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import { AmastyAddType } from '../../type/AmastyPromo.type';
import AmastyGiftPopup from '../AmastyGiftPopup';

import './AmastyGiftAdd.style';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftAdd/Component */
export class AmastyGiftAddComponent extends PureComponent {
    static propTypes = {
        amAdd: AmastyAddType.isRequired,
        isHasCartItems: PropTypes.bool.isRequired,
        handlePopupOpen: PropTypes.func.isRequired
    };

    renderPopup() {
        return (
            <AmastyGiftPopup />
        );
    }

    renderContent() {
        const {
            amAdd: {
                popupLink,
                isHasItems
            },
            isHasCartItems,
            handlePopupOpen
        } = this.props;

        if (!isHasCartItems) {
            return null;
        }

        if (!isHasItems) {
            return null;
        }

        return (
            <>
                <div
                  className="ampromo-items-add"
                >
                    <Html
                      content={ popupLink }
                      handlePopupOpen={ handlePopupOpen }
                    />
                </div>
                { this.renderPopup() }
            </>
        );
    }

    render() {
        return (
            <div block="AmastyGiftAdd">
                { this.renderContent() }
            </div>
        );
    }
}

export default AmastyGiftAddComponent;
