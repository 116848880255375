/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import PropTypes from 'prop-types';
import { useContext } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useSelector } from 'react-redux';

import { GoogleAddressContext } from '../context/GoogleAddress';

export const GOOGLE_ADDRESS_TYPE = 'google-address';

/** @namespace Scandiweb/GoogleAddressSuggestions/Component/GoogleAddress/GoogleAddress */
export const GoogleAddress = ({ addressFieldProps }) => {
    const { onGooglePlaceSelected } = useContext(GoogleAddressContext);

    const isEnabled = useSelector((state) => state.ConfigReducer.google_address_enabled);
    const apiKey = useSelector((state) => state.ConfigReducer.google_address_api_key);

    const { ref } = usePlacesWidget({
        apiKey,
        options: {
            // Overridden to select addresses from Canada only
            componentRestrictions: { country: 'ca' },
            fields: ['address_components'],
            types: ['address']
        },
        onPlaceSelected: (place) => {
            onGooglePlaceSelected(place);
        }
    });

    const {
        type,
        attr,
        events,
        isDisabled,
        setRef
    } = addressFieldProps;

    return (
        <input
          ref={ (elem) => {
              setRef(elem);

              if (isEnabled) {
                  ref.current = elem;
              }
          } }
          // ^^^ Merge refs
          // vvv This comes from Field implementation
          disabled={ isDisabled }
          type={ type }
          { ...attr }
          { ...events }
        />
    );
};

GoogleAddress.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    addressFieldProps: PropTypes.any.isRequired
};

GoogleAddress.displayName = 'GoogleAddress';
