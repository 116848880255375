import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace HotmeTheme/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    /**
     * Overridden to remove tier prices
     */
    renderTierPrice() {
        return null;
    }
}

export default ProductPriceComponent;
