/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import LayoutUpdatesQuery from '../../query/LayoutUpdates.query';

const addYUToRequest = (args, callback) => ([
    ...callback(...args),
    LayoutUpdatesQuery.getQuery()
]);

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addYUToRequest
        }
    }
};
