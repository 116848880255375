/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { GOOGLE_ADDRESS_TYPE } from '../../component/GoogleAddress';

const replaceFirstAddressFieldProps = (args, callback) => {
    const originalAddressFields = callback(...args);
    const [{ default_billing }] = args;
    const isAddressInCheckout = typeof default_billing === 'undefined';

    if (!isAddressInCheckout) {
        // ^^^ Handle only addresses from checkout
        return originalAddressFields;
    }

    originalAddressFields[0].type = GOOGLE_ADDRESS_TYPE;
    // ^^^ Make first input a google address field

    return originalAddressFields;
};

export default {
    'Component/MyAccountAddressForm/Form/getStreetFields': {
        function: replaceFirstAddressFieldProps
    }
};
