/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AmastyGiftAdd from '../../component/AmastyGiftAdd';

const addAmastyPromoToComponent = (args, callback) => (
    <>
        <AmastyGiftAdd />
        { callback(args) }
    </>
);

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render: addAmastyPromoToComponent
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            render: addAmastyPromoToComponent
        }
    }
};
