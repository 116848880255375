/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_AM_PROMO_DATA = 'UPDATE_AM_PROMO_DATA';

/** @namespace Scandiweb/AmastyFreeGift/Store/Config/AmastyPromo/Action/updateAmastyPromoData */
export const updateAmastyPromoData = (amAdd, amItems) => ({
    type: UPDATE_AM_PROMO_DATA,
    amAdd,
    amItems
});
