import NotificationIcon from 'Component/NotificationIcon';
import {
    NotificationComponent as HotmeNotificationComponent
} from 'HotmeComponent/Notification/Notification.component';

import './Notification.override.style';

/** @namespace Scandipwa/Component/Notification/Component */
export class NotificationComponent extends HotmeNotificationComponent {
    renderNotificationIcon() {
        const { notification: { msgType } } = this.props;

        return <NotificationIcon type={ msgType } />;
    }

    /**
     * Overriden to invoke a function to render notification icon based on its type.
     */
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                { this.renderNotificationIcon() }
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
