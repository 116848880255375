import {
    ACCOUNT_FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    COMPARE,
    CONFIRM_ACCOUNT,
    CREATE_ACCOUNT,
    LOGIN,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ContactPage,
    CookiePopup,
    DemoNotice,
    Footer,
    Header,
    HomePage,
    MenuPage,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    SearchPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    withStoreRegex
};

/** @namespace HotmeTheme/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    removedRoutes = [
        ACCOUNT_FORGOT_PASSWORD,
        CHANGE_PASSWORD,
        CONFIRM_ACCOUNT,
        CREATE_ACCOUNT,
        LOGIN,
        MY_ACCOUNT,
        MY_ACCOUNT_ADDRESS,
        MY_ACCOUNT_DOWNLOADABLE,
        MY_ACCOUNT_NEWSLETTER,
        MY_ACCOUNT_ORDER,
        MY_ACCOUNT_ORDERS,
        MY_ACCOUNT_WISHLIST,
        SHARED_WISHLIST,
        SWITCH_ITEMS_TYPE,
        COMPARE
    ];

    /**
     * Overridden to remove wishlist, shared wishlist and compare page
     */
    [SWITCH_ITEMS_TYPE] = this[SWITCH_ITEMS_TYPE].filter(
        ({ name }) => !this.removedRoutes.includes(name)
    );
}

export default RouterComponent;
