/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import DataContainer from 'Util/Request/DataContainer';

import WidgetQuery from '../../query/Widget.query';
import Widget from './Widget.component';

/** @namespace Scandiweb/LayoutUpdates/Component/Widget/Container/WidgetContainer */
export class WidgetContainer extends DataContainer {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    state = {
        widget: {}
    };

    containerFunctions = {};

    componentDidUpdate(prevProps) {
        const { id } = this.props;
        const { id: prevId } = prevProps;

        if (id !== prevId) {
            this.getWidget();
        }
    }

    componentDidMount() {
        this.getWidget();
    }

    containerProps() {
        const { widget } = this.state;

        return {
            widget,
            isShouldDisplay: Object.keys(widget).length
        };
    }

    getWidget() {
        const { id } = this.props;

        if (!id) {
            return;
        }

        this.fetchData(
            [WidgetQuery.getQuery({ widgetId: id })],
            ({ widget }) => {
                const { params } = widget;
                this.setState({
                    widget: {
                        ...widget,
                        params: JSON.parse(params)
                    }
                });
            }
        );
    }

    render() {
        const { id } = this.props;
        const { widget: { id: wId } } = this.state;

        if (wId !== id) {
            return null;
        }

        return (
            <Widget
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default WidgetContainer;
