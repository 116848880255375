/* eslint-disable max-len */
import {
    CartIcon as SourceCartIcon
} from 'SourceComponent/CartIcon/CartIcon.component';

/** @namespace Scandipwa/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M20.887 5.67414C20.727 5.45414 20.507 5.45414 20.427 5.45414H6.858L6.617 4.14014C6.5841 4.01215 6.50891 3.89903 6.40362 3.81915C6.29833 3.73928 6.16913 3.69734 6.037 3.70014H3.58C3.24 3.70014 3 3.93814 3 4.23014C3 4.52314 3.26 4.76014 3.58 4.76014H5.558L6.838 11.7971C6.838 11.8521 6.858 11.9071 6.878 11.9441L7.297 14.3571C7.357 14.6121 7.597 14.7771 7.877 14.7771H18.389C18.729 14.7771 18.969 14.5391 18.969 14.2471C18.969 13.9541 18.709 13.7171 18.389 13.7171H8.356L8.096 12.2911H18.73C18.85 12.3091 18.97 12.2731 19.07 12.2181C19.1306 12.1826 19.1831 12.135 19.2244 12.0782C19.2657 12.0214 19.2949 11.9567 19.31 11.8881L20.968 6.13114C20.9989 6.05514 21.0078 5.97197 20.9937 5.89115C20.9795 5.81033 20.9429 5.73513 20.888 5.67414H20.887ZM9.23 16.1621C8.096 16.1621 7.154 17.0821 7.154 18.2381C7.154 19.3951 8.074 20.3151 9.231 20.3151C10.387 20.3151 11.308 19.3951 11.308 18.2381C11.308 17.0821 10.387 16.1621 9.231 16.1621H9.23ZM9.23 17.4031C9.68 17.4031 10.023 17.7671 10.023 18.2171C10.023 18.6671 9.638 19.0311 9.209 19.0311C9.10323 19.0308 8.9986 19.0093 8.90124 18.968C8.80387 18.9267 8.71573 18.8664 8.64198 18.7905C8.56822 18.7147 8.51033 18.625 8.4717 18.5265C8.43307 18.4281 8.41447 18.3229 8.417 18.2171C8.417 17.7891 8.781 17.4031 9.231 17.4031H9.23ZM16.154 16.1621C15.019 16.1621 14.077 17.0821 14.077 18.2381C14.077 19.3951 14.997 20.3151 16.154 20.3151C17.31 20.3151 18.231 19.3951 18.231 18.2381C18.231 17.0821 17.311 16.1621 16.154 16.1621ZM16.132 17.4031C16.2394 17.4012 16.3461 17.421 16.4457 17.4612C16.5454 17.5015 16.6358 17.5614 16.7118 17.6373C16.7878 17.7133 16.8477 17.8038 16.8879 17.9034C16.9281 18.003 16.9479 18.1097 16.946 18.2171C16.946 18.4329 16.8603 18.6398 16.7077 18.7924C16.5552 18.9449 16.3483 19.0306 16.1325 19.0306C15.9167 19.0306 15.7098 18.9449 15.5573 18.7924C15.4047 18.6398 15.319 18.4329 15.319 18.2171C15.319 17.7891 15.683 17.4031 16.132 17.4031Z" />
            </svg>
        );
    }
}

export default CartIconComponent;
