/**
 * Google Address Suggestions for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { cloneElement } from 'react';

import { GoogleAddressContext, GoogleAddressProvider } from '../../context/GoogleAddress';

const changeAddressProp = (args, callback, instance) => {
    const CheckoutAddressForm = callback(...args);
    const { googleAddress } = instance.props;

    return cloneElement(
        CheckoutAddressForm,
        {
            ...CheckoutAddressForm.props,
            address: googleAddress
        },
        CheckoutAddressForm.props.children
    );
};

const wrapWithGoogleAddressContextConsumer = (args, callback) => {
    const CheckoutAddressComponent = callback(...args);

    return (
        <GoogleAddressProvider>
            <GoogleAddressContext.Consumer>
                { ({ address }) => cloneElement(
                    CheckoutAddressComponent,
                    {
                        ...CheckoutAddressComponent.props,
                        googleAddress: address
                    },
                    CheckoutAddressComponent.props.children
                ) }
            </GoogleAddressContext.Consumer>
        </GoogleAddressProvider>
    );
};

export default {
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            renderCustomAddress: changeAddressProp
        }
    },
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            render: wrapWithGoogleAddressContextConsumer
        }
    }
};
