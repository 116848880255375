/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceAfter, addBlockReferenceBefore } from '../../component/LayoutUpdate';
import { BR_BEFORE_MAIN_COLUMNS, BR_PAGE_BOTTOM, BR_PAGE_TOP } from '../../util/blocks';

export default {
    'Component/Router/Component': {
        'member-function': {
            renderRouterContent: [
                addBlockReferenceBefore(BR_PAGE_TOP),
                addBlockReferenceAfter(BR_BEFORE_MAIN_COLUMNS),
                addBlockReferenceAfter(BR_PAGE_BOTTOM)
            ]
        }
    }
};
