import { connect } from 'react-redux';

import {
    CartDispatcher,
    ConfigDispatcher,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer
} from 'SourceComponent/Router/Router.container';

export {
    mapStateToProps,
    RouterContainer
};

/** @namespace HotmeTheme/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    init: () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
