/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './AmastyGiftMessage.style';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftMessage/Component */
export class AmastyGiftMessageComponent extends PureComponent {
    static propTypes = {
        amCartMessage: PropTypes.string.isRequired
    };

    render() {
        const { amCartMessage } = this.props;

        return (
            <div
              block="CartItem"
              elem="GiftMessage"
            >
                <span>{ amCartMessage }</span>
            </div>
        );
    }
}

export default AmastyGiftMessageComponent;
