/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field/';
import FIELD_TYPE from 'Component/Field/Field.config';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Product from 'Component/Product/Product.component';
import { formatPrice } from 'SourceUtil/Price';
import { ProductType } from 'Type/ProductList.type';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './AmastyGiftItem.style';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftItem/Component */
export class AmastyGiftItemComponent extends Product {
    static propTypes = {
        product: ProductType.isRequired,
        giftsLeft: PropTypes.number.isRequired,
        buttonName: PropTypes.string.isRequired,
        handleAddToCart: PropTypes.func.isRequired,
        isSelectionMethod: PropTypes.bool.isRequired
    };

    renderCheckbox() {
        const {
            product: {
                sku
            },
            isSelected,
            isSelectionMethod,
            handleCheckboxChange,
            getIsGiftSelectable
        } = this.props;

        if (!isSelectionMethod) {
            return null;
        }

        return (
            <div className="ampromo-product-select">
                <Field
                  type={ FIELD_TYPE.checkbox }
                  attr={ {
                      id: `${sku}_checkbox`,
                      name: `${sku}_checkbox`,
                      checked: isSelected,
                      defaultChecked: isSelected
                  } }
                  events={ {
                      onChange: handleCheckboxChange
                  } }
                  isDisabled={ !getIsGiftSelectable() }
                  mods={ { type: 'Select' } }
                />
            </div>
        );
    }

    renderItemTitle() {
        const {
            product: {
                url,
                name
            }
        } = this.props;
        const isVisible = url.indexOf('catalog') === -1;

        if (!isVisible) {
            return (
            <div className="ampromo-item-title">
                <h4 className="ampromo-title">{ name }</h4>
            </div>
            );
        }

        return (
            <div className="ampromo-item-title">
                <h4 className="ampromo-title">
                    <a
                      href={ url }
                      title={ url }
                    >
                        { name }
                    </a>
                </h4>
            </div>
        );
    }

    renderImage() {
        const {
            product: {
                id,
                name
            },
            thumbnail
        } = this.props;

        return (
            <div block="AmastyGiftItem" elem="FigureReview">
                <figure block="AmastyGiftItem" elem="Figure">
                    <Image
                      src={ thumbnail }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'AmastyGiftItem', elem: 'Picture' } }
                      isPlaceholder={ !id }
                    />
                </figure>
            </div>
        );
    }

    renderBasePrice() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        final_price: {
                            value,
                            currency
                        }
                    }
                }
            },
            isShowPrice
        } = this.props;

        if (!isShowPrice) {
            return null;
        }

        return (
            <div
              className="price-base-price"
            >
                    <span className="price-container price-base-price">
                        <span
                          className="price-wrapper"
                        >
                            <span className="price">
                                { formatPrice(value, currency) }
                            </span>
                        </span>
                    </span>
            </div>
        );
    }

    renderPriceInfo() {
        const {
            product: {
                price_range: {
                    maximum_price: {
                        final_price: {
                            currency
                        }
                    }
                }
            },
            giftPrice
        } = this.props;

        return (
            <div className="product-info-price">
                { this.renderBasePrice() }
                <div
                  className="price-new-price"
                >
                    <span className="price-new-price">
                        <span
                          className="price-wrapper"
                        >
                            <span className="price">
                                { formatPrice(giftPrice, currency) }
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderGiftsCounter() {
        const {
            giftsLeft,
            isGiftsCounter
        } = this.props;

        if (!isGiftsCounter) {
            return null;
        }

        return (
            <span className="ampromo-item-qty-left">
                <span>{ giftsLeft }</span>
                { ` ${ __('left') }` }
            </span>
        );
    }

    renderQtyInput() {
        const {
            product: { sku },
            giftQty,
            maxGiftQty,
            isSelectionMethod,
            handleQtyChange,
            getIsGiftSelectable
        } = this.props;

        if (!isSelectionMethod) {
            return null;
        }

        return (
            <div className="ampromo-item-qty-input">
                <div
                  className="ampromo-qty"
                >
                    <Field
                      type={ FIELD_TYPE.number }
                      attr={ {
                          id: `${sku}_giftQty`,
                          name: `${sku}_giftQty`,
                          value: giftQty,
                          defaultValue: giftQty,
                          min: 0,
                          max: maxGiftQty,
                          step: 1
                      } }
                      events={ {
                          onChange: handleQtyChange
                      } }
                      validationRule={ {
                          inputType: VALIDATION_INPUT_TYPE.numeric,
                          range: {
                              min: 0,
                              max: maxGiftQty
                          }
                      } }
                      isDisabled={ !getIsGiftSelectable() }
                      validateOn={ ['onChange'] }
                    />
                </div>
                { this.renderGiftsCounter() }
            </div>
        );
    }

    renderAddToCart() {
        const {
            buttonName,
            isSelectionMethod,
            handleAddToCart,
            getIsGiftSelectable
        } = this.props;

        if (isSelectionMethod) {
            return null;
        }

        return (
            <div className="ampromo-item-buttons">
                <button
                  type="button"
                  mix={ { block: 'Button' } }
                  disabled={ !getIsGiftSelectable() }
                  onClick={ handleAddToCart }
                >
                    <Html content={ buttonName } />
                </button>
            </div>
        );
    }

    render() {
        return (
            <div block="AmastyGiftItem">
                <div
                  className="ampromo-item"
                >
                    <div
                      className="ampromo-items-form"
                    >
                        { this.renderCheckbox() }
                        { this.renderItemTitle() }
                        { this.renderImage() }
                        { this.renderConfigurableOptions() }
                        { this.renderPriceInfo() }
                        { this.renderQtyInput() }
                        { this.renderAddToCart() }
                    </div>
                </div>
            </div>
        );
    }
}

export default AmastyGiftItemComponent;
