export * from 'SourceUtil/Product/Product';

/** @namespace HotmeTheme/Util/Product/checkDate */
export const checkDate = (start, end) => {
    const current = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (current > startDate && current < endDate) {
        return true;
    }

    return false;
};
