/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addAmConfigFieldsToStoreConfig = (args, callback) => ([
    ...callback(...args),
    'am_cart_message',
    'am_notification_text',
    'am_display_notification'
]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addAmConfigFieldsToStoreConfig
        }
    }
};
