/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { fetchQuery } from 'Util/Request';

import { AmastyFreeGiftContext } from '../../context/AmastyFreeGift';
import AmastyPromoQuery from '../../query/AmastyPromo.query';
import { AmastyAddType, AmastyItemsType } from '../../type/AmastyPromo.type';
import { AMASTY_FREE_GIFT_POPUP } from '../AmastyGiftAdd/AmastyGiftAdd.config';
import AmastyPopupItems from './AmastyPopupItems.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyPopupItems/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amAdd: state.ConfigReducer?.amAdd,
    cartId: state.CartReducer?.cartTotals?.id
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyPopupItems/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideMyPopup: (popupKey, payload) => dispatch(hideActivePopup(popupKey, payload)),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyPopupItems/Container */
export class AmastyPopupItemsContainer extends PureComponent {
    static propTypes = {
        amAdd: AmastyAddType.isRequired,
        amItems: AmastyItemsType.isRequired,
        cartId: PropTypes.string.isRequired,
        hideMyPopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired
    };

    static contextType = AmastyFreeGiftContext;

    containerFunctions = {
        handleAddToCart: this.handleAddToCart.bind(this),
        getSliderSettings: this.getSliderSettings.bind(this)
    };

    async handleAddToCart() {
        const {
            cartId,
            hideMyPopup,
            showNotification,
            updateInitialCartData
        } = this.props;
        const { selectedItems } = this.context;

        await fetchQuery([AmastyPromoQuery.getQuery(cartId, selectedItems)]).then(
            /** @namespace Scandiweb/AmastyFreeGift/Component/AmastyPopupItems/Container/AmastyPopupItemsContainer/handleAddToCart/fetchQuery/then */
            ({ amPromoAdd }) => {
                amPromoAdd.map((notification) => {
                    const { status, message } = notification;
                    return showNotification(status, message);
                });
            }
        );

        hideMyPopup(AMASTY_FREE_GIFT_POPUP);
        updateInitialCartData();
    }

    getSliderSettings() {
        return {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            infinite: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
    }

    containerProps() {
        const {
            amItems,
            amAdd: {
                promoSku
            } = {}
        } = this.props;
        const {
            selectedItems
        } = this.context;

        const parsedPromoSku = promoSku ? JSON.parse(promoSku) : {};

        return {
            amItems,
            promoSku: parsedPromoSku,
            selectedItems
        };
    }

    render() {
        return (
            <AmastyPopupItems
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyPopupItemsContainer);
