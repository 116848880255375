import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';

export {
    WidgetFactory
};

/** @namespace Scandipwa/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        document.body.id = 'html-body';
    }
}

export default HtmlComponent;
