/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import { ProductType } from 'Type/ProductList.type';

export const AmastySelectedItemOptionType = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string
}));

export const AmastySelectedItemsType = PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string,
    giftQty: PropTypes.number,
    options: AmastySelectedItemOptionType
}));

export const AmastyAddType = PropTypes.shape({
    commonQty: PropTypes.number,
    popupLink: PropTypes.string,
    popupName: PropTypes.string,
    isOpenAuto: PropTypes.bool,
    isHasItems: PropTypes.bool,
    isCountersMode: PropTypes.bool,
    promoSku: PropTypes.string,
    triggered_products: PropTypes.string
});

export const AmastyItemsType = PropTypes.shape({
    buttonName: PropTypes.string,
    products: PropTypes.arrayOf(ProductType),
    isSelectionMethod: PropTypes.bool,
    isGiftsCounter: PropTypes.bool,
    isShowPrice: PropTypes.bool
});
