/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';
import { CartItemType } from 'Type/MiniCart.type';

import { AmastyFreeGiftContext } from '../../context/AmastyFreeGift';
import { AmastyAddType } from '../../type/AmastyPromo.type';
import AmastyGiftAdd from './AmastyGiftAdd.component';
import { AMASTY_FREE_GIFT_POPUP } from './AmastyGiftAdd.config';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftAdd/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amAdd: state.ConfigReducer?.amAdd,
    cartId: state.CartReducer?.cartTotals?.id,
    cartItems: state.CartReducer?.cartTotals?.items
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftAdd/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showMyPopUp: (popupKey, payload) => dispatch(showPopup(popupKey, payload))
});

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyGiftAdd/Container */
export class AmastyGiftAddContainer extends PureComponent {
    static propTypes = {
        amAdd: AmastyAddType.isRequired,
        showMyPopUp: PropTypes.func.isRequired,
        cartItems: PropTypes.arrayOf(CartItemType).isRequired
    };

    static contextType = AmastyFreeGiftContext;

    containerFunctions = {
        handlePopupOpen: this.handlePopupOpen.bind(this)
    };

    componentDidMount() {
        this.handlePopupAutoOpen();
    }

    componentDidUpdate(prevProps) {
        const {
            amAdd: {
                isOpenAuto: prevIsOpenAuto
            } = {}
        } = prevProps;
        const {
            amAdd: {
                isOpenAuto
            }
        } = this.props;

        if (prevIsOpenAuto !== isOpenAuto) {
            this.handlePopupAutoOpen();
        }
    }

    handlePopupAutoOpen() {
        const {
            amAdd: {
                isOpenAuto
            },
            showMyPopUp
        } = this.props;

        if (!isOpenAuto) {
            return;
        }

        const popupTitle = this.getPopupTitle();

        showMyPopUp(AMASTY_FREE_GIFT_POPUP, { title: popupTitle });
    }

    handlePopupOpen() {
        const {
            showMyPopUp
        } = this.props;

        const popupTitle = this.getPopupTitle();

        showMyPopUp(AMASTY_FREE_GIFT_POPUP, { title: popupTitle });
    }

    getPopupTitle() {
        const {
            amAdd: {
                popupName,
                isCountersMode
            }
        } = this.props;
        const {
            giftsLeft
        } = this.context;

        return isCountersMode ? `${popupName} ( ${giftsLeft} ${ __('left') } )` : popupName;
    }

    containerProps() {
        const { amAdd, cartItems } = this.props;

        /*
         *  created new condition to handle the issue from original extension
         *  available gifts quantity isn't reset when we remove item from the cart
         *  it works fine if at least one gift item is already added to the cart
         *  problem appears when we remove last non-gift item from the cart
         *  so we will check if there are any items in the cart first
        */
        if (!cartItems){
            const isHasCartItems = false;

            return {
                amAdd,
                isHasCartItems
            };
        }
        const isHasCartItems = cartItems.length > 0;

        return {
            amAdd,
            isHasCartItems
        };
    }

    render() {
        return (
            <AmastyGiftAdd
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmastyGiftAddContainer);
