/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';

import Html from 'Component/Html';

import { AmastyItemsType, AmastySelectedItemsType } from '../../type/AmastyPromo.type';
import AmastyGiftItem from '../AmastyGiftItem';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './amslick.min.css';

/** @namespace Scandiweb/AmastyFreeGift/Component/AmastyPopupItems/Component */
export class AmastyPopupItemsComponent extends PureComponent {
    static propTypes = {
        amItems: AmastyItemsType.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        promoSku: PropTypes.object.isRequired,
        selectedItems: AmastySelectedItemsType.isRequired,
        handleAddToCart: PropTypes.func.isRequired,
        handleSelectItem: PropTypes.func.isRequired,
        handleRemoveItem: PropTypes.func.isRequired,
        handleChangeItemQty: PropTypes.func.isRequired,
        getSliderSettings: PropTypes.func.isRequired
    };

    renderGalleryItem = (product) => {
        const {
            amItems,
            promoSku,
            handleAddToCart
        } = this.props;

        if (!product) {
            return null;
        }
        const { sku } = product;
        const giftDetails = promoSku[sku];

        return (
            <AmastyGiftItem
              key={ sku }
              amItems={ amItems }
              product={ product }
              giftDetails={ giftDetails }
              handleAddToCart={ handleAddToCart }
            />
        );
    };

    renderItemsGallery() {
        const {
            amItems: {
                products
            } = {},
            getSliderSettings
        } = this.props;

        if (!products || !products.length) {
            return null;
        }

        return (
            <Slider
              className="ampromo-gallery ampromo-slick"
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...getSliderSettings() }
            >
                { products.map(this.renderGalleryItem) }
            </Slider>
        );
    }

    renderAddToCartButton() {
        const {
            amItems: {
                buttonName,
                isSelectionMethod
            },
            selectedItems,
            handleAddToCart
        } = this.props;

        if (!isSelectionMethod) {
            return null;
        }

        return (
            <div className="ampromo-item-buttons">
                <button
                  type="button"
                  mix={ { block: 'Button' } }
                  disabled={ !selectedItems.length }
                  onClick={ handleAddToCart }
                >
                    <Html content={ buttonName } />
                </button>
            </div>
        );
    }

    render() {
        return (
            <div block="AmastyPopupItems">
                <div className="ampromo-carousel-product">
                    { this.renderItemsGallery() }
                    { this.renderAddToCartButton() }
                </div>
            </div>
        );
    }
}

export default AmastyPopupItemsComponent;
